
var mapElement = document.getElementById('mapsearch');

var buildMap = () => {
  mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
  return new mapboxgl.Map({
    container: 'mapsearch',
    style: 'mapbox://styles/mapbox/streets-v10'
  });
};

var addMarkersToMap = (map, markers) => {
  markers.forEach((marker) => {

    // create the popup
    var popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
      "<img src=" + marker.infoPhoto + ">"
      + '<div class="mapbox-content-info d-flex flex-column">'
      + '<h4 class="map-popup" ><a target="_blank" href="' + "halls/" + marker.infoId + '">' + marker.infoName + '</a></h4>'
      + '<span class="hall-type-info">' + marker.infoHalltype + "</span>"
      + '<span class="button-link" ><a target="_blank" href="' + "halls/" + marker.infoId + '">' + "Voir la salle" + '</a></span>'
      + '</div>'
      );
    // create DOM element for the marker
    var el = document.createElement('div');
    el.id = 'marker' +  marker.infoId;
    el.style.backgroundImage = `url('https://res.cloudinary.com/uppday/image/upload/v1573652247/uppday/Picto/p3ytqmyqtrkhxujmi9th.svg')`;
    el.style.backgroundSize = 'contain';
    el.style.width = '30px';
    el.style.height = '30px';

    new mapboxgl.Marker(el)
      .setLngLat([ marker.lng, marker.lat ])
      .setPopup(popup)
      .addTo(map);
  });
};

var fitMapToMarkers = (map, markers) => {
  var bounds = new mapboxgl.LngLatBounds();
  markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
  map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
};

var initMapboxSearch = () => {
  if (mapElement) {
    var map = buildMap();

    var markers = JSON.parse(mapElement.dataset.markers);
    addMarkersToMap(map, markers);
    fitMapToMarkers(map, markers);
  }
};

export { initMapboxSearch };
