const chartEventType = () => {
  var selectElement = document.getElementById('chart-event-type')

  if (selectElement) {
  var chartEventType = selectElement.getContext('2d');
  const eventType = document.getElementById('event-type');

  const eventValue = eventType.dataset.eventValue;
  const eventKey = eventType.dataset.eventKey;

  var value = JSON.parse(eventValue);
  var key = JSON.parse(eventKey);

  var chartEvent = new Chart(chartEventType, {
    // The type of chart we want to create
    type: 'doughnut',

    // The data for our dataset
    data: {
        labels: key,
        datasets: [{
            label: 'My First dataset',
            backgroundColor: ['#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4'],
            borderColor: ['#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4'],
            data: value
        }]
    },

    // Configuration options go here
    options: {
          legend: {
      display: true,
      position: 'right'
      }
    }
  });
  }
}

const chartUserProfil = () => {
  var selectElement = document.getElementById('chart-user-profil')

  if (selectElement) {
  var chartUserProfil = selectElement.getContext('2d');
  const userProfil = document.getElementById('user-profil');
  console.log(userProfil);
  const profilValue = userProfil.dataset.profilValue;
  const profilKey = userProfil.dataset.profilKey;

  var value = JSON.parse(profilValue);
  var key = JSON.parse(profilKey);

  var chartProfil = new Chart(chartUserProfil, {
    // The type of chart we want to create
    type: 'doughnut',

    // The data for our dataset
    data: {
        labels: key,
        datasets: [{
            label: 'My First dataset',
            backgroundColor: ['#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4'],
            borderColor: ['#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4'],
            data: value
        }]
    },

    // Configuration options go here
    options: {
    legend: {
      display: true,
      position: 'right'
      }
    }
});
  }
}

const chartMonthData = () => {
  var selectElement = document.getElementById('chart-month-data')

  if (selectElement) {
  var chartMonthData = selectElement.getContext('2d');
  const monthData = document.getElementById('month-data');
  const monthValue = monthData.dataset.monthValue;
  const monthKey = monthData.dataset.monthKey;

  var value = JSON.parse(monthValue);
  var key = JSON.parse(monthKey);

  var chartMonth = new Chart(chartMonthData, {
    // The type of chart we want to create
    type: 'bar',

    // The data for our dataset
    data: {
        labels: key,
        datasets: [{
            label: 'Réservations',
            backgroundColor: ['#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4', '#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4'],
            borderColor: ['#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4', '#46BB81', '#2F699B', '#F5B563', '#a9eec2', '#5f93bf', '#f3d0a4'],
            data: value

        }]
    },


    // Configuration options go here
    options: {
            legend: {
        display: false
    },
    tooltips: {
        callbacks: {
           label: function(tooltipItem) {
                  return tooltipItem.yLabel;
           }
        }
    }
    }
});
  }
}

export { chartEventType, chartUserProfil, chartMonthData };
