const calendar = () => {
  const calendarId = document.getElementById('calendar');

  if (calendarId) {
      $('#calendar').fullCalendar({
            locale: 'fr',

            header: {
              left: 'agendaWeek month',
              center: 'title'
            },
            allDaySlot: false,
            events: showBookings(bookings),
            eventRender: function(event, element, view) {
              return $(`

                <a href="/halls/${event.hall_id}/bookings/${event.id}" class="fc-day-grid-event fc-h-event fc-event fc-start fc-end" data-toggle="modal" data-target="#id${event.id}" data-calendar-id="${event.id}">
                  <div class="fc-content ${event.status}">
                    <span class="fc-title"> ${event.first_name} ${event.last_name}</span>
                  </div>
                </a>

                `);
              },

            });
        }
}

const sliderRangePrice = () => {
  const sliderId = document.getElementById('slider-range');

  if (sliderId) {
    const minValue = document.getElementById('hall_price_normal').value;
    console.log(minValue);
    const maxValue = document.getElementById('hall_max_price').value;

  $(function() {

    $("#slider-range").slider({
      range: true,
      min: 0,
      max: 2000,
      values: [minValue, maxValue || 1500],
      slide: function(event, ui){
        $("#hall_price_normal").val(ui.values[0]);
        $("#hall_max_price").val(ui.values[1]);
      }
    });
  })


  }
}

export { calendar, sliderRangePrice };

