const slideUpSearchForm = () => {
  const searchFormBox = document.getElementById("display-search-form-box")
  
  if (searchFormBox) {
    searchFormBox.addEventListener("click", function(){
      const form = document.getElementById("search-form-box")
      if (form.className == "hide-box") {
        const contactForm = document.getElementById("contact-box")
        contactForm.classList.remove("swipe-up-box");
        contactForm.classList.add("hide-box");
        const contactFormBox = document.getElementById("display-contact-box")
        contactFormBox.innerHTML = ''
        contactFormBox.insertAdjacentHTML("afterbegin", 'Réserver');

        const boxBackdrop = document.getElementById("box-backdrop")
        boxBackdrop.classList.add("box-backdrop")

        form.classList.remove("hide-box") 
        form.classList.add("swipe-up-box")
        searchFormBox.innerHTML = ''
        searchFormBox.insertAdjacentHTML("afterbegin", '<i class="fas fa-times"></i>');
      } else {
        const boxBackdrop = document.getElementById("box-backdrop")
        boxBackdrop.classList.remove("box-backdrop")

        form.classList.remove("swipe-up-box");
        form.classList.add("hide-box");
        searchFormBox.innerHTML = ''
        searchFormBox.insertAdjacentHTML("afterbegin", '<i class="fas fa-search-location"></i>');
      }
    });
  }
}

const removeBoxBackdrop = () => {
  const boxBackdrop = document.getElementById("box-backdrop")
  const form = document.getElementById("search-form-box")
  const searchFormBox = document.getElementById("display-search-form-box")
  const contactForm = document.getElementById("contact-box")
  const contactFormBox = document.getElementById("display-contact-box")

  if (boxBackdrop) {
    boxBackdrop.addEventListener("click", function(){
      boxBackdrop.classList.remove("box-backdrop")

      form.classList.remove("swipe-up-box");
      form.classList.add("hide-box");
      searchFormBox.innerHTML = ''
      searchFormBox.insertAdjacentHTML("afterbegin", '<i class="fas fa-search-location"></i>');


      contactForm.classList.remove("swipe-up-box");
      contactForm.classList.add("hide-box");
      contactFormBox.innerHTML = ''
      contactFormBox.insertAdjacentHTML("afterbegin", 'Réserver');
    })
  }
}

const slideUpContact = () => {
  const contactFormBox = document.getElementById("display-contact-box")
  
  if (contactFormBox) {
    contactFormBox.addEventListener("click", function(){
      const contactForm = document.getElementById("contact-box")
      if (contactForm.className == "hide-box") {
        const form = document.getElementById("search-form-box")
        form.classList.remove("swipe-up-box");
        form.classList.add("hide-box");
        const searchFormBox = document.getElementById("display-search-form-box")
        searchFormBox.innerHTML = ''
        searchFormBox.insertAdjacentHTML("afterbegin", '<i class="fas fa-search-location"></i>');


        const boxBackdrop = document.getElementById("box-backdrop")
        boxBackdrop.classList.add("box-backdrop")

        contactForm.classList.remove("hide-box") 
        contactForm.classList.add("swipe-up-box")
        contactFormBox.innerHTML = ''
        contactFormBox.insertAdjacentHTML("afterbegin", '<i class="fas fa-times"></i>');
      } else {
        const boxBackdrop = document.getElementById("box-backdrop")
        boxBackdrop.classList.remove("box-backdrop")

        contactForm.classList.remove("swipe-up-box");
        contactForm.classList.add("hide-box");
        contactFormBox.innerHTML = ''
        contactFormBox.insertAdjacentHTML("afterbegin", 'Réserver');
      }
    });
  }
}
  
export { slideUpSearchForm, slideUpContact, removeBoxBackdrop };