const navbarSm = () => {
    $(".display-cards-fixed").scroll(function() {

    if ($(this).scrollTop() > 50){
        $('#navbar-show-sm').addClass("is_visible");
    }
    else{
        $('#navbar-show-sm').removeClass("is_visible");
    }
});

}

const navbarInfoSm = () => {
    $(window).scroll(function() {

    if ($(this).scrollTop() > 50){
        $('#navbar-info-sm').addClass("is_visible_i");
    }
    else{
        $('#navbar-info-sm').removeClass("is_visible_i");
    }
});

  var navItems = jQuery('.menu-item a');
  var win = jQuery(window);
  var items = jQuery('.item');


  navItems.click(function(e){
      var item = jQuery(this);

      jQuery('.menu-item a.active').removeClass('active');
      item.addClass('active');
  });


win.scroll(function(e){
    jQuery.each(items, function(key, value){
        var item = jQuery(value);
        console.log(win.scrollTop(), item.offset().top);
        if(win.scrollTop() >= item.offset().top){
            jQuery('.menu-item a.active').removeClass('active');
            var id = item.attr('id');

            jQuery.each(navItems, function(key, value){
                var navItem = jQuery(value);
                if(navItem.attr('href') == '#'+id) navItem.addClass('active');
            });
        }
    });
});

}

export { navbarSm, navbarInfoSm };
