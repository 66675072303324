const copyLink = () => {
  $(document).ready(function(){
    $(document).on('click','#copyLink', function(){
          const linkId = document.getElementById('copyLink')
          const link = linkId.dataset.text
          copytext(link);

      })
  });

  const copytext = (text) => {
      const textField = document.createElement('textarea');
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      textField.focus(); //SET FOCUS on the TEXTFIELD
      document.execCommand('copy');
      textField.remove();
      document.getElementById("copyLink").innerHTML = '<i class="fas fa-copy"></i> Lien copié';
  }
}

export { copyLink };
