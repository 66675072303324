const collapseText = () => {
    $(".collapse-text").ready(function() {
    $("#toggle").click(function() {
      var elem = $("#toggle").text();
      if (elem == "Lire plus") {
        //Stuff to do when btn is in the read more state
        $("#toggle").text("Lire moins");
        $("#text").slideDown();
      } else {
        //Stuff to do when btn is in the read less state
        $("#toggle").text("Lire plus");
        $("#text").slideUp();
      }
    });
  });
};

export { collapseText };
