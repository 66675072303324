const hoverMap = () => {
  $(".cards-search").on('mouseover', function(){
    $('#marker' + $(this).attr("data-id")).addClass('hover')
    console.log("salut")
  });

  $(".cards-search").mouseout(function() {
    $('#marker' + $(this).attr("data-id")).removeClass('hover')
  });
}

export { hoverMap };
