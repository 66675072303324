import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import 'flatpickr/dist/flatpickr.min.css';

const French = require("flatpickr/dist/l10n/fr.js").default.fr;
flatpickr.localize(French);

const initPicker = () => {
  const pickerElement = document.getElementById('booking_start');
  const disabledDateElement = document.getElementById('disabled-date');

  // console.log(disabledDateElement.dataset.disabledDate);

  if (pickerElement) {
    const disabledDate = disabledDateElement.dataset.disabledDate;
    flatpickr(pickerElement, {
      minDate: "today",
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "j F, Y",
      disable: JSON.parse(disabledDate)

    });
  }
}

const initPickerEnd = () => {
  const pickerElement = document.getElementById('booking_end');
  const disabledDateElement = document.getElementById('disabled-date');

  if (pickerElement) {
    const disabledDate = disabledDateElement.dataset.disabledDate;
    const startInput = document.getElementById('booking_start');
    pickerElement.disabled = true;
    startInput.addEventListener('input', ()=>{
      pickerElement.disabled = false;
      flatpickr(pickerElement, {
        minDate:  startInput.value,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "j F, Y",
        disable: JSON.parse(disabledDate)
      });
    })
  }
}


const changeStartDate = () => {
  const myInputs = document.getElementsByClassName('change-start-date');;
  flatpickr(myInputs, {
    dateFormat: "d-m-Y",
  });
}

const changeEndDate = () => {
  const myInputs = document.getElementsByClassName('change-end-date');
  flatpickr(myInputs, {
    dateFormat: "d-m-Y",
  });
}


export { initPicker };
export { initPickerEnd };
export { changeStartDate };
export { changeEndDate };

