import 'bootstrap/dist/js/bootstrap';
import "bootstrap";
import "../plugins/flatpickr";

// From Components
import { navbarSm, navbarInfoSm } from '../components/navbarSm';
import { copyLink } from '../components/copyLink'
import { slideUpSearchForm, slideUpContact, removeBoxBackdrop } from '../components/slide_up'

//from PlugIn
import { initMapbox } from '../plugins/init_mapbox';
import { initMapboxSearch } from '../plugins/init_search';
import { initPicker } from '../plugins/flatpickr';
import { initPickerEnd } from '../plugins/flatpickr';
import { calendar, sliderRangePrice } from '../plugins/calendar';
import { chartEventType, chartUserProfil, chartMonthData } from '../plugins/chart';
import { dragdrop } from '../plugins/dragdrop';
import { collapseText } from '../plugins/collapse_text';
import { hoverMap } from '../plugins/hover_map';
import { expandText, searchForm } from '../plugins/expand_text';
import { initSweetalert } from '../plugins/init_sweetalert';
import { initToastr } from '../plugins/toastr';

initToastr();
initMapbox();
initMapboxSearch();
initPicker();
initPickerEnd();
slideUpSearchForm();
slideUpContact();
removeBoxBackdrop();

calendar();
sliderRangePrice();
chartEventType();
chartUserProfil();
chartMonthData();
navbarSm();
navbarInfoSm();
dragdrop();
collapseText();
hoverMap();
expandText();
copyLink();

initSweetalert('#sweet-alert-pa', {
  title: "Êtes-vous sûr de vouloir pré-accepter la réservation?",
  text: "En pré-acceptant, les documents liés à la salle seront automatiquement envoyés au client.",
  icon: "info"
}, (value) => {
  if (value) {
    const link = document.querySelector('#pa-link');
    link.click();
  }
});

initSweetalert('#sweet-alert-approve', {
  title: "Êtes-vous sûr de vouloir accepter définitivement la réservation ?",
  text: "En effectuant cette action un e-mail d’acceptation sera automatiquement envoyé au client.",
  icon: "success"
}, (value) => {
  if (value) {
    const link = document.querySelector('#approve-link');
    link.click();
  }
});

initSweetalert('#sweet-alert-cancel', {
  title: "Êtes-vous sûr de vouloir annuler cette réservation ?",
  text: "En effectuant cette action un e-mail de d'annulation sera automatiquement envoyé au client.",
  icon: "error"
}, (value) => {
  if (value) {
    const link = document.querySelector('#cancel-link');
    link.click();
  }
});


import "controllers"
