const expandText = () => {
  $('.expand-button').on('click', function(){
    $('.special-text').toggleClass('-expanded');

    if ($('.special-text').hasClass('-expanded')) {
      $('.expand-button').html('Masquer <i class="fas fa-chevron-up"></i>');
    } else {
      $('.expand-button').html('En savoir plus sur la salle <i class="fas fa-chevron-down"></i>');
    }
  });

  $('.expand-button-price').on('click', function(){
    $('.special-text-price').toggleClass('-expanded');

    if ($('.special-text-price').hasClass('-expanded')) {
      $('.expand-button-price').html('Masquer <i class="fas fa-chevron-up"></i>');
    } else {
      $('.expand-button-price').html('Détail des tarifs de la salle <i class="fas fa-chevron-down"></i>');
    }
  });
}

export { expandText };